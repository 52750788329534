module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/qoalich/socialtechs.co/socialtechs.web/frontend/src/intl","languages":["en","ru"],"defaultLanguage":"ru","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"socialtechs-site","short_name":"socialtechs","start_url":"/","background_color":"#007efa","theme_color":"#007efa","display":"minimal-ui","icon":"src/images/nav-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"1348b038d048a66bef2e2c1143f37569"},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrica/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"46451400","clickmap":true,"trackLinks":true,"accurateTrackBounce":true,"trackHash":true,"webvisor":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"357359091981760"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
